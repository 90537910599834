<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-0">
        <v-card flat tile
                :width="$vuetify.breakpoint.width"
                :min-height="$vuetify.breakpoint.height - 192"
                class="presize-padding all"
                color="#F9F9FA">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="pa-0 ma-0 mt-n16">
              <app-back-button-component/>
            </v-col>
          </v-row>
          <v-card-title class="cmr-title px-0 d-flex justify-center">
            <span class="ml-6">{{ title }}</span>
          </v-card-title>
          <v-card-text class="pt-sans">
            <validation-observer v-slot="{ passes }">
              <v-form @submit.prevent="passes(submit)">
                <v-card elevation="1" max-width="45%" class="ml-auto my-auto" min-height="240" color="#fff">
                  <v-card-title class="px-6 pt-4 pb-3">
                    <span class="font-weight-bold text-body-1">Um einen Bericht zu erhalten, müssen Sie die obligatorischen Daten hinzufügen</span>
                    <span class="text-body-1">Füllen Sie das Formular</span>
                  </v-card-title>
                  <v-card-text class="px-6 py-3">
                    <validation-provider name="Vorname und Nachname" rules="required" v-slot="{ errors }">
                      <v-text-field type="text"
                                    v-model="form.name"
                                    :error-messages="errors"
                                    placeholder="Vorname und Nachname*">
                        <template v-slot:prepend>
                          <v-icon color="#000"
                                  style="font-size: 32px"
                                  class="mr-5">
                            mdi-account-circle-outline
                          </v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                    <validation-provider name="Firma / Dienststelle" rules="required" v-slot="{ errors }">
                    <v-text-field type="text"
                                  v-model="form.organization"
                                  :error-messages="errors"
                                  placeholder="Firma / Dienststelle*">
                      <template v-slot:prepend>
                        <v-icon color="#000"
                                style="font-size: 32px"
                                class="mr-5">
                          mdi-briefcase-variant-outline
                        </v-icon>
                      </template>
                    </v-text-field>
                    </validation-provider>
                    <validation-provider name="E-Mail-Adresse" rules="required|email" v-slot="{ errors }">
                    <v-text-field type="email"
                                  v-model="form.email"
                                  :error-messages="errors"
                                  placeholder="E-Mail-Adresse*">
                      <template v-slot:prepend>
                        <v-icon color="#000"
                                style="font-size: 32px"
                                class="mr-5">
                          mdi-email-outline
                        </v-icon>
                      </template>
                    </v-text-field>
                    </validation-provider>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end px-3 py-3">
                    <v-btn text plain type="submit">
                      <span>prüfung beginnen</span>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CMR",
  data () {
    return {
      form: {
        name: null,
        email: null,
        organization: null
      }
    }
  },
  mounted() {

  },
  methods: {
    submit () {
      this.$store.dispatch('setPersonalization', this.form)
      .then(() => {
        this.$router.push({ name: 'Questions'})
      })
    }
  },
  computed: {
    title () {
      return this.$route.params.title
    }
  }
}
</script>

<style scoped lang="scss">
.cmr-title {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 32px !important;
  color: #18283B;
  transition: .45s ease-in-out;
  & .cmr-title-number {
    color: #D8BC76;
    font-size: 100px;
    opacity: .5;
  }
}
</style>