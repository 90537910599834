var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"presize-padding all",attrs:{"flat":"","tile":"","width":_vm.$vuetify.breakpoint.width,"min-height":_vm.$vuetify.breakpoint.height - 192,"color":"#F9F9FA"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"pa-0 ma-0 mt-n16",attrs:{"cols":"12"}},[_c('app-back-button-component')],1)],1),_c('v-card-title',{staticClass:"cmr-title px-0 d-flex justify-center"},[_c('span',{staticClass:"ml-6"},[_vm._v(_vm._s(_vm.title))])]),_c('v-card-text',{staticClass:"pt-sans"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.submit)}}},[_c('v-card',{staticClass:"ml-auto my-auto",attrs:{"elevation":"1","max-width":"45%","min-height":"240","color":"#fff"}},[_c('v-card-title',{staticClass:"px-6 pt-4 pb-3"},[_c('span',{staticClass:"font-weight-bold text-body-1"},[_vm._v("Um einen Bericht zu erhalten, müssen Sie die obligatorischen Daten hinzufügen")]),_c('span',{staticClass:"text-body-1"},[_vm._v("Füllen Sie das Formular")])]),_c('v-card-text',{staticClass:"px-6 py-3"},[_c('validation-provider',{attrs:{"name":"Vorname und Nachname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"placeholder":"Vorname und Nachname*"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"mr-5",staticStyle:{"font-size":"32px"},attrs:{"color":"#000"}},[_vm._v(" mdi-account-circle-outline ")])]},proxy:true}],null,true),model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Firma / Dienststelle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"placeholder":"Firma / Dienststelle*"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"mr-5",staticStyle:{"font-size":"32px"},attrs:{"color":"#000"}},[_vm._v(" mdi-briefcase-variant-outline ")])]},proxy:true}],null,true),model:{value:(_vm.form.organization),callback:function ($$v) {_vm.$set(_vm.form, "organization", $$v)},expression:"form.organization"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"E-Mail-Adresse","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","error-messages":errors,"placeholder":"E-Mail-Adresse*"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"mr-5",staticStyle:{"font-size":"32px"},attrs:{"color":"#000"}},[_vm._v(" mdi-email-outline ")])]},proxy:true}],null,true),model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"d-flex justify-end px-3 py-3"},[_c('v-btn',{attrs:{"text":"","plain":"","type":"submit"}},[_c('span',[_vm._v("prüfung beginnen")]),_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }